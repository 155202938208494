/* Focus */
*:focus {
    /*outline: var(--projcolor) auto 3px;*/
}

/* Legend of Colors */
:root {
    --black: #000;
    --white: #fff;
    --gray: #eee;
    --darkGray: #292429;
    --projcolor: red;
}

::selection {
    background: var(--black);
    color: var(--white);
}

/* Homepage Color */

:root .home {
    --projcolor: var(--white);
    transition: 200ms ease-in-out;
}

@media (min-width: 768px) {
    :root .home {
        --projcolor: #222;
        transition: 200ms ease-in-out;
    }
}

.end {
    margin-bottom: 128px;
}

/* MICA Phisher Color */
:root .phisher {
    --projcolor: #fedb00;
    transition: 200ms ease-in-out;
}

/* Cluse Color */
:root .cluse {
    --projcolor: rgb(70, 159, 255);
    transition: 200ms ease-in-out;
}

/* Ditto + Banditto Color */
:root .ditto {
    --projcolor: #00feb3;
    transition: 200ms ease-in-out;
}

/* Trunks Color */
:root .trunks {
    --projcolor: #f1753a;
    transition: 200ms ease-in-out;
}

/* AVAM Color */
:root .avam {
    --projcolor: #eff1fa;
    transition: 200ms ease-in-out;
}

/* Mad Lads Color */
:root .madlads {
    --projcolor: #ffd200;
    transition: 200ms ease-in-out;
}

/* Semaphore Color */
:root .semaphore {
    --projcolor: #7e52ff;
    transition: 200ms ease-in-out;
}

/* BitShit Color */
:root .bitshit {
    --projcolor: #8465ff;
    transition: 200ms ease-in-out;
}

/* Lissitzky Color */
:root .lissitzky {
    --projcolor: #e74d3c;
    transition: 200ms ease-in-out;
}

/* Spiral Draw Color */
:root .spiral {
    --projcolor: #5dcdcf;
    transition: 200ms ease-in-out;
}

/* Tar Pits Color */
:root .tarpits {
    --projcolor: #ff36d9;
    transition: 200ms ease-in-out;
}

/* Oriental Carpets Color */
:root .carpets {
    --projcolor: #bd0f00;
    transition: 200ms ease-in-out;
}

/* Decred Color */
:root .decred {
    --projcolor: #2dd8a3;
    transition: 200ms ease-in-out;
}

/* ARTECA Color */
:root .arteca {
    --projcolor: #fee700;
    transition: 200ms ease-in-out;
}

/* Yext Color */
:root .yext {
    --projcolor: #375c3a;
    transition: 200ms ease-in-out;
}

/* Hero Wrapper Grid Responsiveness */

.hero__wrapper {
    z-index: -10;
}

/* TABLET VERSION */

@media (min-width: 768px) and (max-width: 1400px) {
    div > .hero__wrapper {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-template-rows: auto auto;
    }
}

/* DESKTOP VERSION */

@media (min-width: 1024px) {
    .hero__wrapper {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-template-rows: auto auto;
    }
}

/* Misc Global Settings */
html {
    scroll-behavior: smooth;
}
