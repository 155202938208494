.home__txt--wrapper {
  overflow-x: hidden;
}

.home__txt {
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero__txt {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}

.home__brow {
  text-align: left;
  color: var(--black);
  font-family: Chapeau-Medium;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 1.3px;
  transition: 500ms ease-in-out;
}

.home__head {
  font-family: Ogg;
  font-weight: 500;
  font-size: 30px;
  color: var(--black);
  letter-spacing: 1.33px;
  text-transform: capitalize;
  text-decoration: none;
  transition: 500ms ease-in-out;
}

.link__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 90vw;
  border-bottom: 2px var(--black) solid;
}

.link__wrapper:hover .home__head {
  transition: 200ms ease-in-out;
  color: var(--black);
}

.link__wrapper:hover {
  border-bottom-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='Squiggle-svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.st0{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23000' stroke-width='1' class='st0' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: bottom;
  background-size: auto 8px;
  background-repeat: repeat-x;
  text-decoration: none;
}

/* Hovers Effects for the Hero */

.home__head:hover ~ .home__hero {
  background-color: red;
}

a {
  text-decoration: none;
}

.home__title {
  position: absolute;
  color: var(--black);
  font-size: 1.3vw;
  font-family: Chapeau-Medium;
  line-height: 130%;
  font-weight: 400;
  width: 60%;
  opacity: 0;
  transition: 500ms ease-in-out;
}

/* TABLET + DESKTOP VERSION */

@media (min-width: 768px) {
  .home__head {
    text-align: left;
    font-size: 32px;
  }

  .home__brow {
    font-size: 12px;
  }
}

/* DESKTOP VERSION */
@media (min-width: 1024px) {
  .link__wrapper:hover .home__brow {
    transition: 800ms ease-in-out;
  }

  .link__wrapper:hover .home__head {
    opacity: 0;
    filter: blur(50px);
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    transition: 800ms ease-in-out;
  }

  .link__wrapper:hover .home__title {
    opacity: 1;
    transition: 800ms ease-in-out;
  }

  .home__txt--wrapper {
    position: relative;
  }

  .link__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 38vw;
    border-bottom: 2px var(--black) solid;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .home__txt::-webkit-scrollbar {
    display: none;
  }

  .home__txt:after {
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    content: '';
    pointer-events: none;
  }

  .home__txt {
    overflow: scroll;
    align-items: flex-start;
    text-align: left;
    height: 50vw;
    padding-bottom: 6vw;
    padding-top: 3vw;
    padding-left: 6vw;
    align-items: flex-start;
  }

  .home__head {
    font-size: 3vw;
    line-height: 2.75vw;
  }
}
