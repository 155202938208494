.home__hero {
  display: none;
  position: relative;
}

/* TABLET VERSION */

@media (min-width: 768px) and (max-width: 1023px) {
  .home__hero {
    display: none;
    position: relative;
  }
}

/* DESKTOP VERSION */

@media (min-width: 1024px) {
  .home__hero {
    display: block;
    height: 80vh;
    transition: 150ms ease-in-out;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .hero {
    grid-row-start: 1;
    background-color: var(--black);
    grid-row-end: span 2;
    border-right: 2px var(--black) solid;
    border-bottom: 2px var(--black) solid;
    z-index: 2;
  }

  figure.home__img {
    transition: 250ms ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  figure.home__img img {
    max-width: 60vw;
    max-height: 60vh;
  }

  .home__img--limiter {
    max-width: 80%;
  }

  /* Hero Image */
  .marquee__wrap {
    align-self: flex-end;
    width: 40vw;
    white-space: nowrap;
    background-color: var(--white);
    border-top: 2px var(--black) solid;
    padding-top: 16px;
    padding-bottom: 16px;
    font-family: Chapeau-Medium;
    font-weight: 500;
    font-size: 20px;
    color: var(--black);
    letter-spacing: 1.11px;
    line-height: 24px;
  }
}
