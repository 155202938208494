.home {
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: white;
}

::-webkit-scrollbar-thumb {
    background-color: #5e56d4;
    border-radius: 20px;
}
